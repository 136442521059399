import React from "react";
import Footer from "../../../components/shared/Footer/Footer";
import HomeTwoNavbar from "../../Home/HomeTwoNavbar/HomeTwoNavbar";
import HomeArea from "../HomeArea/HomeArea";
import Directories from "../Home/BannerArea/BannerArea";
import AboutArea from "./AboutArea/AboutArea";
import ChoseArea from "./ChoseArea/ChoseArea";
import HeroSection from "./HeroSection/HeroSection";
import ProgressArea from "./ProgressArea/ProgressArea";
import ServiceHome from "../Home/ServicesHome/ServiceHome";
import WorkingProcess from "../../../pages/About/About/WorkingProcess/WorkingProcess";
import ContactMap from "../../Contact/Contact/ContactMap/ContactMap";
import Modal from "../Home/Modal/Modal";

const Home = () => {
  return (
    <>
      <HomeTwoNavbar />
      <HeroSection />
      <HomeArea />
      <Directories />
      <ProgressArea />
      <AboutArea />
      <WorkingProcess />
      <div className="row justify-content-center pt-30">
        <div className="col-lg-3 col-md-6 text-center content-directorios">
          <a href="https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf" target="_blank">
            <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2FSW-1.jpg?alt=media&token=288b0d1c-3606-4e99-98a9-5f3539cd0081" alt="Colors"></img>
          </a>
        </div>
        <div className="col-lg-3 col-md-6 text-center content-directorios">
          <a href="https://www.behr.com/consumer/colors/paint/" target="_blank">
            <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbehr-1.jpg?alt=media&token=5b20facf-8923-4bad-9c02-b2d6d9f5b44e" alt="Colors"></img>
          </a>
        </div>
        <div className="col-lg-3 col-md-6 text-center content-directorios">
          <a href="https://www.benjaminmoore.com/en-us/color-overview/color-palettes" target="_blank">
            <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbjm-1.jpg?alt=media&token=30c85c07-dcc1-4601-81cf-40186bbc014b" alt="Colors"></img>
          </a>
        </div>
      </div>
      <ServiceHome />
      <ChoseArea />
      <ContactMap />
      <Modal />
      <Footer />
    </>
  );
};

export default Home;
